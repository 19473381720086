import React from 'react';
import './Cards.css';
import CardItem from './CardItem';


function Cards() {
  return (
    <div className='cards'>
      <h1>CAMP PARADISE CHRISTIAN RETREAT 
EXISTS TO HELP PEOPLE <p className="hero--sub-txt">SEE, KNOW, AND EXPERIENCE GOD</p></h1>

<h1>CAMP PARADISE IS A YEAR-ROUND CAMP AND RETREAT CENTER IN<p className="hero--sub-txt">  STRAWBERRY VALLEY, CA </p></h1>

<h1>COME SEE WHY THOUSAND OF GUESTS RETREAT TO OUR MOUNTAIN LOCATION EACH YEAR
FOR   <p className="hero--sub-txt">LIFE-TRANSFORMING MINISTRY AND LIFE-LONG MEMORIES</p></h1>

<div className="divider"><span></span><span><h1 className="testimony-hero">TESTIMONIES</h1></span><span></span></div>



      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
      
              text="We spend two weeks at the end of summer at Camp Paradise with the CFMS Earth Science Seminars . It is a wonderful place, the facilities are lovely."
              subtext='- Cheri George'
              label='testimony'
              // path='/services'
            />
            <CardItem
          
              text="This camp doesn't compare to any other. Best Campground I've ever been to. Snows in the winter and it is beautiful!'"
              subtext='- Bryan Karavan'
              label='testimony'
              // path='/services'
            />
          
          </ul>
          <ul className='cards__items'> <CardItem
     
              text="Best camp for the money"
              subtext='- Lazar Suprun'
              label='testimony'
              // path='/products'
            />
            
            <CardItem
            
              text="I loved going as a kid now sending my son"
              subtext='- Jason Crump'
              label='testimony'
              // path='/services'
            />
           
            <CardItem

              text="Great place for a camping!"
              subtext='- Andrei Duscov'
              label='testimony'
              // path='/sign-up'
            />
          </ul>
          <div className="reviews">
          <center>
          <p>To see all of our reviews click the link below</p> 
          </center>
          <a href="https://www.google.com/search?q=camp+pardise&oq=camp+pardise&aqs=chrome..69i57j35i39j0j46i175i199l2j0l3.8545j0j7&sourceid=chrome&ie=UTF-8#lrd=0x809c90e50603a7bf:0x55a576b96957b750,1,,," style={{marginTop:"1rem"}}>Reviews</a></div>
          

        </div>
      </div>
    </div>
  );
}

export default Cards;
