
import One from "../Assets/Images/3.jpg"
import Two from "../Assets/Images/200.jpg"
import Three from "../Assets/Images/5.jpg"
import Four from "../Assets/Images/2.jpg"
import Five from "../Assets/Images/31.jpg"
import Six from "../Assets/Images/16.JPG"
import Seven from "../Assets/Images/19.jpg"
import Eight from "../Assets/Images/27.jpg"




export const photos = [
  {
    src: One,
    width: 4,
    height: 3
  },
  {
    src:Two,
    width: 1,
    height: 1
  },

  {
    src: Three,
    width: 4,
    height: 3
  },
  {
    src: Four,
    width: 1,
    height: 1
  },
  {
    src: Five,
    width: 1,
    height: 1
  },
  {
    src: Seven,
    width: 4,
    height: 3
  },
  {
    src: Six,
    width: 1,
    height: 1
  },
  {
    src: Eight,
    width: 4,
    height: 3
  },

  
  
];
