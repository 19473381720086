import React from 'react'
import "./Activities.css"
function Activities() {
    return (
        <div>
            <h1 className="act-h1">Activities</h1>
            <center>
            <div className='act-deck'>
<div className="act-sec-one">
    <div className="act-card">

        
        <div >
        <img src="https://images.unsplash.com/photo-1585077017412-1b54a783b8ac?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80" alt="swimming pool" className='act-card-image'/>
        </div><div className='act-card-title'>
            <h3 className="act-h3">Swimming Pool</h3>
        </div>
        <div className='act-card-descr'>
           
        </div>
    </div>

    <div className="act-card">

        
<div >
<img src="https://images.unsplash.com/photo-1592656094267-764a45160876?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80" alt="volleyball" className='act-card-image'/>
</div><div className='act-card-title'>
    <h3 className="act-h3">Volleyball</h3>
</div>
<div className='act-card-descr'>
   
</div>
</div>
    
    <div className="act-card">

        
<div >
<img src="https://images.unsplash.com/photo-1494199505258-5f95387f933c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2253&q=80" alt="Basketball" className='act-card-image'/>
</div><div className='act-card-title'>
    <h3 className="act-h3">Basketball</h3>
</div>
<div className='act-card-descr'>
  
</div>
</div>

    
</div>






<div className="act-sec-two">

<div className="act-card">

        
<div >
<img src="https://images.unsplash.com/photo-1523471826770-c437b4636fe6?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80" alt="Sauna" className='act-card-image'/>
</div><div className='act-card-title'>
    <h3 className="act-h3">Sauna</h3>
</div>
<div className='act-card-descr'>
   
</div>
</div>

<div className="act-card">


<div >
<img src="https://images.unsplash.com/uploads/1412533519888a485b488/bb9f9777?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80" alt="Hiking" className='act-card-image'/>
</div><div className='act-card-title'>
<h3 className="act-h3">Hiking</h3>
</div>
<div className='act-card-descr'>

</div>
</div>

<div className="act-card">


<div >
<img src="https://images.unsplash.com/photo-1616840388998-a514fe2175b9?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80" alt="Frisbee golf" className='act-card-image'/>
</div><div className='act-card-title'>
<h3 className="act-h3">Frisbee golf</h3>
</div>
<div className='act-card-descr'>

</div>

</div>







</div>
<div className="act-sec-three">
<div className="act-card">


<div >
<img src="https://images.unsplash.com/photo-1591423013713-fa38c9330b6c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=3368&q=80" alt="Playground" className='act-card-image'/>
</div><div className='act-card-title'>
<h3 className="act-h3">Kid's Playground</h3>
</div>
<div className='act-card-descr'>

</div>

</div>

<div className="act-card">


<div >
<img src="https://media.istockphoto.com/photos/horseshoe-on-the-stake-and-one-landing-in-the-pit-picture-id524328419?k=6&m=524328419&s=612x612&w=0&h=TpdVWM8yIEsjGxrDnS9cTYsWMORocJy5WakRu1wDsq4=" alt="Horseshoe Pitching" className='act-card-image'/>
</div><div className='act-card-title'>
<h3 className="act-h3">Horseshoe Pitching</h3>
</div>
<div className='act-card-descr'>

</div>

</div>

<div className="act-card">


<div >
<img src="https://images.unsplash.com/photo-1575361204480-aadea25e6e68?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2251&q=80" alt="Field activities" className='act-card-image'/>
</div><div className='act-card-title'>
<h3 className="act-h3">Field activities</h3>
</div>
<div className='act-card-descr'>

</div>

</div>
</div>



<div className="act-section-four">


<div className="act-card">


<div >
<img src="https://d2x8z2o9lo5dqx.cloudfront.net/images/dtbset-lifestyle2_l.jpg" alt="Tether ball" className='act-card-image'/>
</div><div className='act-card-title'>
<h3 className="act-h3">Tether ball</h3>
</div>
<div className='act-card-descr'>

</div>

</div>
<div className="act-card">


<div >
<img src="https://images.unsplash.com/photo-1615544983150-0760f39857f5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2243&q=80" alt="Pull-up bars" className='act-card-image'/>
</div><div className='act-card-title'>
<h3 className="act-h3">Pull-up Bars</h3>
</div>
<div className='act-card-descr'>

</div>

</div>
<div className="act-card">


<div  >
<img src="https://ih1.redbubble.net/image.716125041.2339/st,small,507x507-pad,600x600,f8f8f8.u4.jpg" alt="plus sign"  style={{backgroundColor: 'lightgray', }} className='act-card-image'/>
</div><div className='act-card-title'>
<h3 className="act-h3">Plus Much More To Come!</h3>
</div>
<div className='act-card-descr'>

</div>

</div>


</div>

</div>
</center>


            </div>
     
    )
}

export default Activities
