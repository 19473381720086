import React from 'react';

import Cards from '../Cards';
import HeroSection from '../HeroSection';


function Home() {
  return (
    <>
      <HeroSection />
      <Cards />
      
    </>
  );
}

export default Home;
