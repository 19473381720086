import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import Retreat from "../Assets/Images/Retreat.mp4"
import { Link } from 'react-router-dom';
import HeroImage from "../Assets/Images/206.JPG"


function HeroSection() {
  
  return (
    <div className='hero-container'>
      <video src={Retreat} autoPlay loop muted /> 
   
      <img src={HeroImage} className='heroimage' alt='hero'/>
      <h1>SEE, KNOW, AND EXPERIENCE GOD</h1>
      <p>What are you waiting for?</p>
      
      <div className='hero-btns'>
      <Link   to='/contact' className='btn-mobile'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          BOOK NOW
        </Button>
        </Link>
        <a href="https://youtu.be/XupSgY8heYU" className='btn-mobile'>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          // onClick={console.log('hey')}
        >
          WATCH TRAILER <i className='far fa-play-circle' />
        </Button></a>
      </div>
    </div>
  );
}

export default HeroSection;
