import React from 'react';
import './Profiles.css';
import Ben from "../Assets/Images/ben.jpg"
import Duscov from "../Assets/Images/family.jpeg"
import Tim from "../Assets/Images/tim.jpg"
import Victor from "../Assets/Images/victor.jpeg"

function Profiles() {
  return (
    <div className="card-deck">

<div className='card'>
<div className='card-body'>
<center>
<img  src={Ben} className="image" alt="profile"/></center>
<div className="text"> <h3>Benjamin Baljic</h3>
    <p>Camp President</p></div>
   
</div>


</div>


<div className='card'>
<div className='card-body'>
<center>
<img src={Victor} className="image" alt="profile"/>
</center>

<div className="text"> <h3>Victor Merkushev</h3>
    <p>Camp Director
</p></div>
</div>


</div>

<div className='card'>
<div className='card-body'>
<center> <img src={Tim} className="image" alt="profile"/></center>

<div className="text"> <h3>Tim Vasko
</h3>
    <p>Financial Officer
</p></div>
</div>


</div>

<div className='card'>
<div className='card-body'>
<center> <img  src={Duscov} className="image" alt="profile" style={{objectFit: "contain", width: "15rem",
  height: "15rem"}}/></center>

<div className="text"> <h3>Pozdnyakov Family
</h3>
    <p>Property Managers
</p></div>
</div>


</div>



  </div>
  );
}

export default Profiles;


