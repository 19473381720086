import ImageSix from "../Assets/Images/6copy.jpg"
import ImageSeven from "../Assets/Images/7copy.jpg"
import ImageTwo from "../Assets/Images/2copy.jpg"
import Nine from "../Assets/Images/28.jpg"
import Ten from "../Assets/Images/201.jpg"
import Eleven from "../Assets/Images/14.jpg"
import Twelve from "../Assets/Images/25.jpg"
import Thirteen from "../Assets/Images/22.jpg"


export const photostwo = [
   
    {
      src:Nine ,
      width: 4,
      height: 3
    },
    {
      src: Twelve,
      width: 1,
      height: 1
    },
  
    {
      src: Eleven,
      width: 4,
      height: 3
    },
    {
      src: Ten,
      width: 1,
      height: 1
    },
    {
        src: ImageSix,
        width: 1,
        height: 1
      },
      {
        src: Thirteen,
        width: 4,
        height: 3
      },
      {
        src: ImageSeven,
        width: 1,
        height: 1
      },
      {
        src: ImageTwo,
        width: 4,
        height: 3
      },
    
  ];
  