import React from 'react'



import "./Contact.css"
import { useForm, ValidationError } from '@formspree/react';

import Profiles from "../Profiles"




function Contact() {

   

    const [state, handleSubmit] = useForm("xeqvaggp");
  
    if (state.succeeded) {
        return <center className="success-page"><p className="success-message">We will get back to you as soon as we can!</p></center>
    }
    return (
        <div>
        <div className="contact-container">
      <div className="contact-title">Contact</div>
      <div className="contact-description">
      If you'd like to book a stay with us don't hesitate to reach out!
      </div>
      <div className="contact-block">
        <div className="block-1">
          <div className="address">
            <i className="fas fa-map-marker-alt"></i>
            <div className="address-details">
              <p>Location</p>
              <p>12725 La Porte Rd, Strawberry Valley, CA 95981</p>
            </div>
          </div>
          <div className="email">
            <i className="fas fa-envelope"></i>
            <div className="email-details">
              <p>Email</p>
              <a href="mailto: Contact@camp-paradise.org" style={{color:"black"}} > Contact@camp-paradise.org</a>
            </div>
          </div>
          <div className="phone">
            <i className="fas fa-phone-alt"></i>
            <div className="phone-details">
              <p>Call</p>
              <a href="tel:(916) 573-2267" style={{color:"black"}} >  (916) 573-2267</a>
            </div>
          </div>

          <div className="map">
            <iframe
              title="iframe"
              frameborder="0"
              scrolling="yes"
              marginheight="0"
              marginwidth="0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3076.4928087653543!2d-121.14139478470267!3d39.54849431590687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809c90e50603a7bf%3A0x55a576b96957b750!2sCamp%20Paradise!5e0!3m2!1sen!2sus!4v1597259432055!5m2!1sen!2sus"
            ></iframe>
          </div>
        </div>
        <div className="block-2">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="name">Group Name</label>
                <input id="full-name"
        type="txt" 
        name="group name"
        placeholder="your group name"
        required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Contact Email</label>
                <input    id="email"
        type="email" 
        name="email"
        placeholder="email@domain.com"
        required />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="telephone"> Telephone Number </label>
              <input id="telephone"
        type="telephone" 
        name="telephone"
        placeholder="(555) 555-5555"
        required />
         <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
            </div>
            <div className="form-group">
              <label htmlFor="Message">Additional Information</label>
              <textarea id="message"
        name="additional information"
        placeholder="Include any additional information."></textarea>
         <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
            </div>
            <div className="btn-container">
              <button type="submit" disabled={state.submitting}  >Send Message</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <h1 className="leadership-title">Camp Leadership</h1>
   <Profiles/>
    </div>
    )
}

function Form() {
    return (
      <Contact/>
    );
  }

export default Form
