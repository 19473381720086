import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';

import Dropdown from './Dropdown';

import logo from '../Assets/Images/Logo.png';

function Navbar() {

 

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const onMouseEnter = () => {
    if (window.innerWidth < 100) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 100) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };


  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
      
        <div className='navbar-container'>  
        <Link to='/' > <img className="navlogo" src={logo} alt={logo} style={{ width: '3.3rem'}} />  </Link>   
       
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            CAMP PARADISE
    
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>


            <li
            className='nav-item'
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            
          >
            <Link 
              
              className='nav-links'
              onClick={closeMobileMenu}
             
              
            >
              About <i id="arrow"   className='fas fa-caret-down' />
            </Link>
            {dropdown && <Dropdown onClose= {closeMobileMenu}/>}
          </li>
            

            <li className='nav-item' >
              <Link
                to='/gallery'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Gallery
              </Link>
            </li>
            

            {/* <li className='nav-item' >
              <Link
                to='/store'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Store
              </Link>
            </li> */}
            

            <li className='nav-item' >
              <Link
                to='/donate'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Donate
              </Link>
            </li>
            

            <li className='nav-item' >
              <Link
                to='/contact'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Contact
              </Link>
            </li>

           
            <li>
              <a
                href="https://app.camp-paradise.org"
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                LOG IN
              </a>
            </li>
            
          </ul>
          <a   href="https://app.camp-paradise.org">
          {button && <Button buttonStyle='btn--outline'  href="https://app.camp-paradise.org">LOG IN</Button>}
          </a>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
