import React from 'react'
import "./Donate.css"



function Donate() {
    return (
        <div className="dona-main">
        <h1 className="dona-h1">Donate</h1>
        <center>
        <h2 className="dona-h4" > If you have the desire to support our mission, please donate using the below options</h2>
        </center>
        
<div className="donation-deck">


<div className="dona-card-one">

<iframe allowpaymentrequest frameBorder={0} height="900px" name="donorbox" scrolling="no" seamless="seamless" src="https://donorbox.org/embed/camp-paradise" title="camp paradise donation" style={{maxWidth: '500px', minWidth: '250px', maxHeight: 'none!important'}} width="100%" />

</div>

<div className="dona-sec-2">
<div className="dona-card-two">
<center>
<h3 className="dona-h3">If you'd like to donate directly to our PayPal click the button below!</h3>
<center>
</center>

<div className="dona-paypal">
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                <input type="hidden" name="cmd" value="_donations" />
                <input type="hidden" name="business" value="reservation@camp-paradise.org" />
                <input type="hidden" name="currency_code" value="USD" />
                <input
                  type="image"
                  src="https://simple-membership-plugin.com/wp-content/uploads/2018/09/paypal-smart-payment-button-for-simple-membership.jpg"
                  border="0"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donate with PayPal button"
                  style={{ width: '18rem', height: '9rem' ,borderRadius: "4px" }}
                />
                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="5" height="5" className="donation" />
              
              </form>
            </div></center>
          


</div>





</div>








</div>
<center>

<h2  className="dona-h4"  > Even the smallest donations go a long way, so we thank you from the bottom of our hearts.<br/> God bless you for your generosity!
</h2>
</center>


    
        </div>
    )
}

export default Donate
