import React from 'react';
import "./About.css"

import picone from "../../Assets/Images/8.jpg"
import pictwo from "../../Assets/Images/2.jpg"


export default function About() {
  return (
  <div className="about-parent">

  <div className="about-sections">
  
<div className="about-one">
<img src={picone} alt=" camp "  className="about-image"/>
<center><p style={{marginTop: "-2rem"}}></p></center>

<img src={pictwo} alt=" group of people "  className="about-image-two"/>
</div>

<div className="about-two">

<h2 className="mission-title">Our Mission</h2>
<p className="mission-statment-para">Our mission at Camp Paradise is to provide a unique retreat environment with quality facilities
where guests can retreat in comfort and security and where everyone is challenged to grow in
their faith and relationship with Jesus Christ. We exist to evangelize and minister to children and
youth in California for Jesus Christ through Christian camping. We desire that each person
coming into contact with this global ministry will: accept Jesus Christ as their personal Savior,
grow in their faith and Christian character development, establish the priorities of prayer, Bible
study, and Christian fellowship while devoting their lives in service to our Lord Jesus Christ.
</p>

<h2 className="mission-title">Our Vision</h2>
<p  className="mission-statment-para">Our vision at Camp Paradise is to have it be a place that provides solely Christ-centered retreats, seminars, and counseling. Our goal is to lead guests to engage the beauty of God’s creation, the joy of fellowship, and the challenge of growth in their faith and walk with Jesus Christ. We want to EMPOWER people in their walk with Jesus, EQUIP them to share their Christian faith, and ENCOURAGE everyone through a life-changing camping experience.</p>

<h2 className="mission-title">Statement Of Faith</h2>
<p className="mission-statment-para"> We believe in God the Father, the Son, and the Holy Spirit. We especially emphasize the deity of Jesus Christ and the personality of the Holy Spirit. We believe that human beings are born in sin; that they need the work of forgiveness through Christ and the new birth by the Holy Spirit; that subsequent to this there is the deeper work of heart cleansing or entire sanctification through the infilling of the Holy Spirit, and that to each of these works of grace the Holy Spirit gives witness. We believe that our Lord will return, the dead shall be raised, and that all shall come to final judgment with its rewards and punishments. 
</p>
</div>



</div>



  </div>
  
  
  
    );
}
