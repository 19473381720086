import React from 'react'
import "./Lodging.css"
import LodgingPic from "../../Assets/Images/lodging.jpg"
import Chapel from "../../Assets/Images/chapel.jpg"
import Kitchen from "../../Assets/Images/kitchen.jpg"
import Sauna from "../../Assets/Images/sauna.JPG"






function Lodging() {
    
    return (
     <div>
     
<h1 className="lodging-h1">Facilities</h1>
<div className="lodging-deck">

<div className="lodging-card">
<img className='lodging-image' src={LodgingPic }  alt="lodging "/>
    
<div className='lodging-split'>
<div className='lodging-title'>
<h1 className="title-h1">Lodging</h1>




</div>

<div className='lodging-description'>
<p className="lodging-p"> VIP Dorm - Main sleeping quarters and first building that catches your eye as you enter the retreat. Accommodates ~80 individuals and offers restrooms/showers. <br/>
</p>
<p className="lodging-p">Dorms - 2 more large dorms. Accommodates ~80 individuals and offers restrooms/showers. </p>
<p className="lodging-p">Cabins - We have a total of 7 cabins. Each cabin accommodates ~12 people per cabin. There is a standalone building with restrooms and showers.</p>
<p className="lodging-p">RV Parking - There are 16 RV parking slots with all the hookups, suitable for your full-size SUV. </p>
<p className="lodging-p">Tents - Feel free to bring your own tent and set up camp anywhere you would like. </p>

</div>

</div>





</div>
<div className="lodging-card">
<img className='lodging-image' src={Kitchen }  alt="kitchen "/>
    
<div className='lodging-split'>
<div className='lodging-title'>
<h1 className="title-h1">Dining Hall & Kitchen</h1>




</div>

<div className='lodging-description'>
<p className="lodging-p"> The dining hall has a capacity to serve ~130 people and a lot more on the outside deck. There is a huge kitchen with commercial equipment that will suit all your cooking needs. Private sleeping quarters are available for kitchen staff. <br/>
</p>

</div>

</div>





</div>


<div className="lodging-card">
<img className='lodging-image' src={Chapel }  alt="chapel "/>
    
<div className='lodging-split'>
<div className='lodging-title'>
<h1 className="title-h1">Chapel/Gym</h1>




</div>

<div className='lodging-description'>
<p className="lodging-p"> This building can fit 300+ people for your church services and can also be converted into a gym. We also have a pool and ping-pong table available at your request. <br/>
</p>

</div>

</div>





</div>


<div className="lodging-card">
<img className='lodging-image' src={Sauna }  alt="kitchen "/>
    
<div className='lodging-split'>
<div className='lodging-title'>
<h1 className="title-h1">Sauna</h1>




</div>

<div className='lodging-description'>
<p className="lodging-p">Take some time to unwind in our wood-burning dry sauna and forget about your daily worries. Here you will also find showers to wash off and a resting room to enjoy some tea and great conversations with your friends.  <br/>
</p>

</div>

</div>





</div>



















{/* <div className="lodging-card">
<div className='lodging-title'>
<h1 className="title-h1">Cabins</h1>




</div>
<div className='lodging-image'>
    
</div>


<div className='lodging-description'>
<center><p className="lodging-p"> We also have 7 little cabins that each hold a total of 12 individuals.
</p></center>


</div>


</div>



<div className="lodging-card">
<div className='lodging-title'>
<h1 className="title-h1">VIP</h1>




</div>
<div className='lodging-image'>
    
</div>


<div className='lodging-description'>
<center><p className="lodging-p"> VIP is the first building you see as you drive into your retreat and it holds roughly 80 individuals.
</p></center> */}
{/* 


</div>


</div>


<div className="lodging-card">
<div className='lodging-title'>
<h1 className="title-h1">RV Parking</h1>




</div>
<div className='lodging-image'>
    
</div>


<div className='lodging-description'>
<center><p className="lodging-p"> We currently also have available 16 RV parking spots for folks to stay at.
</p></center>

</div>


</div> */}

{/* <div className="lodging-card">
<div className='lodging-title'>
<h1 className="title-h1">Tents</h1>




</div>
<div className='lodging-image'>
    
</div>


<div className='lodging-description'>
<center><p className="lodging-p"> With tents feel free to set one up where ever you'd like.
</p></center>


</div>


</div> */}





</div>







{/* <center><h3 className="lodging-h3">Sleeping quarters accommodates a little over 300 people. All of our sleeping quarters have heating systems. We have 3 long dorms and 7 little cabins. Our dorms sleep around 80 individuals. Cabins are all standard and accommodate 12 people, that is 6 bunk beds. We also have an RV area for 16 RVs. We have special kitchen staff rooms with their own showers and bathrooms. Medical Personnel has their own first-aid room on site.</h3></center> */}






     </div>
    )
}

export default Lodging
