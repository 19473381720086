import React from 'react'

import Gallery from "react-photo-gallery";

import { photos } from "../SliderData"
import {photostwo} from "../SliderDataTwo"




function GalleryPics() {


  return (
    <div >
    <Gallery photos={photos} />
    <Gallery photos={photostwo} />
  
  </div>
  );
};

export default GalleryPics;