import React from 'react';
import Navbar from './components/Navbar';
import Footer from "./components/Footer"
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import About from './components/pages/About';
import Gallery from './components/pages/Gallery';
// import Store from './components/pages/Store';
import Donate from "./components/pages/Donate"
import Contact from "./components/pages/Contact"

// Sublink Imports
import Mission from "./components/pages/Mission"
import Lodging from "./components/pages/Lodging"
import Activities from "./components/pages/Activities"
import Location from "./components/pages/Location"
// import StatmentOfFaith from "./components/pages/StatmentOfFatith"

import { useEffect } from "react";
import { useLocation } from "react-router-dom";


 function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function App() {
  return (
    <>
    <Router  >
    <ScrollToTop/>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/mission' component={About} />
          <Route path='/gallery' component={Gallery} />
          {/* <Route path='/store' component={Store} /> */}
          <Route path='/donate' component={Donate} />
          <Route path='/contact' component={Contact} />

          {/* Sublink Routes */}
          <Route path='/mission' component={Mission} />
          <Route path='/facilities' component={Lodging} />
          <Route path='/activities' component={Activities} />
          <Route path='/location' component={Location} />
          {/* <Route path='/statment-of-faith' component={StatmentOfFaith} /> */}
        </Switch>
        <Footer/>
        
      </Router>
    </>
  );
}

export default App;
