import React from 'react';
import './Footer.css';

import { Link } from 'react-router-dom';

import logo from '../Assets/Images/Logo.png';


function Footer() {
  return (
    <div className='footer-container'>
      
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items' >
            <h2>About Us</h2>
            <Link to='/mission' >Our Misson</Link>
            <Link to='/facilities'> Facilities</Link>
            <Link to='/activities'>Activities</Link>
            <Link to='/location'>Location</Link>
            {/* <Link to='/statment-of-faith'>Statment of Faith</Link> */}
          </div>
          <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/contact'>Contact</Link>
            <Link to='/donate'>Support Us</Link>
            <Link to='/location'>Location</Link>
            
          </div>
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <a href="https://instagram.com/camp_paradise?igshid=18cp28vhnn12w" >Instagram </a>
            <a href="https://www.facebook.com/pages/Camp-Paradise/1636950613259373">Facebook</a>
            <a href="https://www.youtube.com/channel/UCidjYEP3aP_tEPtxyaBnWCA">Youtube</a>
         
          </div>
        </div>
      
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
          <center>
            <Link to='/' className='social-logo'>
            CAMP PARADISE
            <img className="logo" src={logo} alt={logo} style={{ width: '3.3rem'}} /> 
            </Link></center>
          </div>
          <small className='website-rights'>David Shestopal ©    {new Date().getFullYear()}</small>
          <div className='social-icons'>
            <a
              className='social-icon-link facebook'
              href='https://www.facebook.com/pages/Camp-Paradise/1636950613259373'
              // target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </a>
            <a
              className='social-icon-link instagram'
              href='https://instagram.com/camp_paradise?igshid=18cp28vhnn12w'
              // target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </a>
            <a
              className='social-icon-link youtube'
              href='https://www.youtube.com/channel/UCidjYEP3aP_tEPtxyaBnWCA'
              // target='_blank'
              aria-label='Youtube'
            >
              <i className='fab fa-youtube' />
            </a>
            
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
