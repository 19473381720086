import React from 'react'
import "./Location.css"



function Location() {
    return (
        <div className="location-main">
            <h1 className='location-title'>Location</h1>
            <center> <h3 className="location-h3">Located Just 1.5 Hours From Sacramento and 1.25 Hours From Chico! </h3></center>
          
            <div>
            <center>
      <iframe
        className="location-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3076.4928087653543!2d-121.14139478470267!3d39.54849431590687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809c90e50603a7bf%3A0x55a576b96957b750!2sCamp%20Paradise!5e0!3m2!1sen!2sus!4v1597259432055!5m2!1sen!2sus"
        frameBorder={0}
        style={{ border: 0 }}
        allowFullScreen
        aria-hidden="false"
        tabIndex={0}
        title="myFrame"
      /></center>
    </div>
    
    
        </div>
    )
}

export default Location
