export const MenuItems = [
 
  {
    title: 'Mission',
    path: '/mission',
    cName: 'dropdown-link'
  },



    {
      title: 'Facilities',
      path: '/facilities',
      cName: 'dropdown-link'
    },
    {
      title: 'Activities',
      path: '/activities',
      cName: 'dropdown-link'
    },
    {
      title: 'Location',
      path: '/location',
      cName: 'dropdown-link'
    },
    // {
    //   title: 'Statement Of Faith',
    //   path: '/statment-of-faith',
    //   cName: 'dropdown-link'
    // },
   
  ];