import React from 'react';
// import { Link } from 'react-router-dom';

function CardItem(props) {
  return (
    <>
      <li className='cards__item'>
        <div className='cards__item__link' to={props.path}>
       
          {/* <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt='Travel '
              src={props.src}
            />
          </figure> */}
          <div className='cards__item__info'><center>  <h5 className='cards__item__text'>"{props.text}"</h5></center>
          <div className="testimony"><center><p>{props.subtext}</p></center></div>
           <p style={{display:"flex", justifyContent:"center", marginTop: "2rem"}}>⭐⭐⭐⭐⭐</p>
          </div>
        </div>
      </li>
    </>
  );
}

export default CardItem;
